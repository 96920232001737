<!-- eslint-disable vue/no-v-html -->
<template>
	<b-row
		class="d-flex justify-content-center align-items-center flex-column"
		style="margin: 5px 20px;">
		<i
			class="fa fa-exclamation-circle fa-5x"
			style="color: #f86c6b; margin-bottom:25px;"
			aria-hidden="true" />
		<div class="text-center">
			<h3 style="margin-bottom:25px;">
				{{ translate('force_username_change_title') }}
			</h3>
			<h6 style="line-height: 150%;">
				{{ translate('force_username_change_text') }}
			</h6>
			<h6
				style="line-height: 150%; margin: 15px 0px;"
				v-html="translate('important_replicated_site')" />
		</div>
		<div
			:style="!['xs'].includes(windowWidth) ? 'width: 500px;' : 'width: 100%;'">
			<b-input-group
				id="username"
				class="mb-3">
				<b-input-group-prepend><b-input-group-text><i class="icon-user" /></b-input-group-text></b-input-group-prepend>
				<b-form-input
					v-model.trim="username"
					:class="errors.length > 0 ? 'is-invalid' : ''"
					:placeholder="translate('new_username')"
					type="text"
					name="username"
					@input="errors = []" />
				<template v-if="errors.length > 0">
					<span
						v-for="error in errors"
						:key="error"
						class="invalid-feedback animated fadeIn"
						v-text="error" />
				</template>
			</b-input-group>
			<b-button
				variant="primary"
				class="px-4 py-2 btn-block"
				@click="editProfile">
				{{ translate('save') }}
			</b-button>
		</div>
	</b-row>
</template>

<script>

import ProfileInfo from '@/util/Profile';
import { ForceUsernameChange } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'ForceUsernameChange',
	messages: [ForceUsernameChange],
	mixins: [WindowSizes],
	data() {
		return {
			username: '',
			profile: new ProfileInfo(),
			alert: new this.$Alert(),
		};
	},
	computed: {
		errors: {
			get() {
				try {
					return this.profile.data.errors.errors.username ?? [];
				} catch (error) {
					return [];
				}
			},
			set(newErrors) {
				this.profile.data.errors.errors.username = newErrors;
			},
		},
	},
	methods: {
		editProfile() {
			this.profile.updateProfile(this.$user.details().id, { username: this.username }).then(() => {
				this.$user.setUser({ force_username_change: 0, username: this.username });
				this.alert.toast('success', this.translate('profile_updated'));
				this.$router.replace({ name: 'MainHome' });
			}).catch(() => {});
		},
	},
};
</script>
